@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .animate{
      display: flex;
      animation: bounce 1s infinite;
    },
    .animate-spin{
    animation: spin 1s linear infinite;
},
    .animate-pulse{
        	animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    },

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
  @keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  }}